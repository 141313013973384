// ### Par défaut
// {% if recaptcha_site_key() %}
//   <input type="hidden" name="g-recaptcha-response" data-recaptcha-site-key="{{ recaptcha_site_key() }}" data-recaptcha-action="recaptcha_{{ form.id }}">
// {% endif %}
// <button type="submit" class="c-button c-form__button g-recaptcha">{{ submit_text }}</button>

// ### Avec bandeau RGPD tarteaucitron
// {% if recaptcha_site_key() %}
//   <input type="hidden" name="g-recaptcha-response" data-recaptcha-site-key="{{ recaptcha_site_key() }}" data-recaptcha-action="recaptcha_{{ form.id }}">
// {% endif %}
// {% set submit %}
//   <button type="submit" class="c-button c-form__button g-recaptcha">{{ submit_text }}</button>
// {% endset %}
// <div class="g-recaptcha" data-button="{{ submit|trim|e }}"></div>

var grecaptcha_hidden_fields = [];

var grecaptcha_global_loaded = false;
var grecaptcha_global_callback = function() {
  grecaptcha_global_loaded = true;

  grecaptcha_hidden_fields.forEach(function($hidden) {
    var action = $hidden.data('recaptcha-action');
    var site_key = $hidden.data('recaptcha-site-key');

    grecaptcha.ready(function () {
      grecaptcha.execute(site_key, {action: action}).then(function (token) {
        $hidden.val(token);
        if ($hidden.data('submit-me')) {
          $hidden.closest('form').submit();
        }
      });
    });
  });
};

(function($) {
  $(function() {
    var grecaptcha_global_load_inited = false;
    var autoload_recaptcha_forms = [];

    var init_recaptcha = function($form) {
      if ($form.hasClass('g-recaptcha--inited')) return;
      $form.addClass('g-recaptcha--inited');

      var $hidden = $form.find('input[name="g-recaptcha-response"]');
      grecaptcha_hidden_fields.push($hidden);

      if (grecaptcha_global_loaded) {
        grecaptcha_global_callback();
        return;
      }
      
      if (grecaptcha_global_load_inited) return;
      grecaptcha_global_load_inited = true;

      var site_key = $hidden.data('recaptcha-site-key');
      $body.append('<script src="https://www.google.com/recaptcha/api.js?render=' + site_key + '&onload=grecaptcha_global_callback"></script>');
    };

    // https://stackoverflow.com/questions/21561480/trigger-event-when-user-scroll-to-specific-element-with-jquery
    var scroll_listener = function() {
      autoload_recaptcha_forms.forEach(function($form) {
        var elementTop = $form.offset().top,
            elementHeight = $form.outerHeight(),
            windowHeight = $window.height(),
            windowScroll = $window.scrollTop();

        // if (windowScroll > (elementTop + elementHeight - windowHeight)){
        if (windowScroll > (elementTop - windowHeight)){
          var index = autoload_recaptcha_forms.indexOf($form);
          autoload_recaptcha_forms.splice(index, 1);
          if (autoload_recaptcha_forms.length == 0) {
            $window.unbind('scroll', scroll_listener);
          }

          init_recaptcha($form);
        }
      });
    };
    $window.bind('scroll', scroll_listener);

    var submit_form = function() {
      var $form = $(this);

      var $submit = $form.find('button[type=submit]');
      if ($submit.length == 0) {
        return false;
      }
      $submit.prop('disabled', true);

      var $hidden = $form.find('input[name="g-recaptcha-response"]');
      if ($hidden.val()) {
        return true;
      }
      $hidden.data('submit-me', true);

      init_recaptcha($form);
      return false;
    };

    $('.g-recaptcha').each(function() {
      var $submit = $(this);
      var $form = $submit.closest('form');
      var $hidden = $form.find('input[name="g-recaptcha-response"]');
      $form.on('submit', submit_form);

      if ($hidden.length == 0) {
        return;
      }
      else if ($submit.is('button[type=submit]')) {
        autoload_recaptcha_forms.push($form);
      }
      else if (typeof(tarteaucitron) != 'undefined') {
        (tarteaucitron.job = tarteaucitron.job || []).push('recaptcha');
        tarteaucitron.user.recaptchaapi = $hidden.data('recaptcha-site-key');

        tarteaucitron.user.recaptchaOnLoad = function() {
          $submit.replaceWith($submit.data('button'));

          grecaptcha_global_loaded = true;        // exécution auto de grecaptcha_global_callback
          grecaptcha_global_load_inited = true;   // ne pas charger recaptcha/api.js deux fois

          // init_recaptcha($form);               // génération du token tout de suite : non
          autoload_recaptcha_forms.push($form);   // reprise de la détection de scroll
        };
      }
    });
  });
})(jQuery);
