var $window = $(window),
  $document = $(document),
  $html     = $('html'),
  $body     = $('body');

// require blankshield/blankshield.js
// $(function() {
//   $document.on('click', 'a[href]:not([href="#"])', function (e) {
//     var this_hostname = String(this.hostname).replace(/^[a-z]{2}\./, '');
//     var location_hostname = String(location.hostname).replace(/^[a-z]{2}\./, '');

//     var isExternal = !this.href.match(/^(mailto|tel)\:/) &&               // Disable if mailto: or tel:
//                      !this.download &&                                    // Disable if download attribute
//                      (this.href.match(/\.(pdf|xls|doc|ppt)/) ||           // Enable if documents
//                      this_hostname != location_hostname ||                // Enable if different domain
//                      (this.target && this.target == '_blank') ||          // Enable if target _blank
//                      (this.rel && this.rel.match(/external|noopener/)));  // Enable if rel=external or noopener

//     if (isExternal) {
//       blankshield.open(this);
//       e.preventDefault();
//     }
//   });
// });

$(document).find('a[href]:not([href="#"])').each(function (index, link) {
  var isBlankable =
    !link.href.match(/^(mailto|tel)\:/) &&                                                     // Ignore mailto and tel
    (link.href.match(/\.(pdf|xls|doc|ppt)/) ||                                                 // Enable if document
    (link.hostname != location.hostname && (!link.download || !link.target || !link.rel)));    // Enable if different domain with no download, target or rel attributes

  if (isBlankable) {
    link.target = "_blank";
    link.rel = "noopener";
  }
});
/**
 * _browser.js
 * Display a message to non supported browser.
 *
 * If needed you can update the markup of the message
 * and/or the body of browserIsCompatible() function to adjust the test based on which browsers you want to support.
 */

$(function() {
  'use strict';

  var html = '<p class="c-browser">\
                <strong>Votre navigateur est désuet !</strong>\
                <br />\
                Mettez à jour votre navigateur pour afficher correctement ce site Web.\
                <br />\
                <a href="http://outdatedbrowser.com/fr" class="button">\
                  <span class="button__text">Mettre à jour maintenant</span>\
                </a>\
              </p>';

  function browserIsCompatible(){
    // By default we treat all browsers has compatible
    var isCompatible = true;

    /*
     * No IE versions supports @supports{} and if we rely on caniuse stats this feature is pretty well supported :
     * https://caniuse.com/#search=supports
     * So this must cover our comptaibility expectations (usually last 2 versions)
     */
    var cssSupports = 'CSS' in window && 'supports' in window.CSS || 'supportsCSS' in window;
    // Target IE11 only : https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // If doesn't support @supports{} and is not IE11
    if(!cssSupports && !isIE11){
      isCompatible = false;
    }

    return isCompatible;
  }

  // When DOM is ready, if browser is not supported then we display the disclaimer message.
  $(document).ready(function(){
    if(!browserIsCompatible()){
      $('body').prepend(html);
    }
  });

});

// ### Par défaut
// {% if recaptcha_site_key() %}
//   <input type="hidden" name="g-recaptcha-response" data-recaptcha-site-key="{{ recaptcha_site_key() }}" data-recaptcha-action="recaptcha_{{ form.id }}">
// {% endif %}
// <button type="submit" class="c-button c-form__button g-recaptcha">{{ submit_text }}</button>

// ### Avec bandeau RGPD tarteaucitron
// {% if recaptcha_site_key() %}
//   <input type="hidden" name="g-recaptcha-response" data-recaptcha-site-key="{{ recaptcha_site_key() }}" data-recaptcha-action="recaptcha_{{ form.id }}">
// {% endif %}
// {% set submit %}
//   <button type="submit" class="c-button c-form__button g-recaptcha">{{ submit_text }}</button>
// {% endset %}
// <div class="g-recaptcha" data-button="{{ submit|trim|e }}"></div>

var grecaptcha_hidden_fields = [];

var grecaptcha_global_loaded = false;
var grecaptcha_global_callback = function() {
  grecaptcha_global_loaded = true;

  grecaptcha_hidden_fields.forEach(function($hidden) {
    var action = $hidden.data('recaptcha-action');
    var site_key = $hidden.data('recaptcha-site-key');

    grecaptcha.ready(function () {
      grecaptcha.execute(site_key, {action: action}).then(function (token) {
        $hidden.val(token);
        if ($hidden.data('submit-me')) {
          $hidden.closest('form').submit();
        }
      });
    });
  });
};

(function($) {
  $(function() {
    var grecaptcha_global_load_inited = false;
    var autoload_recaptcha_forms = [];

    var init_recaptcha = function($form) {
      if ($form.hasClass('g-recaptcha--inited')) return;
      $form.addClass('g-recaptcha--inited');

      var $hidden = $form.find('input[name="g-recaptcha-response"]');
      grecaptcha_hidden_fields.push($hidden);

      if (grecaptcha_global_loaded) {
        grecaptcha_global_callback();
        return;
      }
      
      if (grecaptcha_global_load_inited) return;
      grecaptcha_global_load_inited = true;

      var site_key = $hidden.data('recaptcha-site-key');
      $body.append('<script src="https://www.google.com/recaptcha/api.js?render=' + site_key + '&onload=grecaptcha_global_callback"></script>');
    };

    // https://stackoverflow.com/questions/21561480/trigger-event-when-user-scroll-to-specific-element-with-jquery
    var scroll_listener = function() {
      autoload_recaptcha_forms.forEach(function($form) {
        var elementTop = $form.offset().top,
            elementHeight = $form.outerHeight(),
            windowHeight = $window.height(),
            windowScroll = $window.scrollTop();

        // if (windowScroll > (elementTop + elementHeight - windowHeight)){
        if (windowScroll > (elementTop - windowHeight)){
          var index = autoload_recaptcha_forms.indexOf($form);
          autoload_recaptcha_forms.splice(index, 1);
          if (autoload_recaptcha_forms.length == 0) {
            $window.unbind('scroll', scroll_listener);
          }

          init_recaptcha($form);
        }
      });
    };
    $window.bind('scroll', scroll_listener);

    var submit_form = function() {
      var $form = $(this);

      var $submit = $form.find('button[type=submit]');
      if ($submit.length == 0) {
        return false;
      }
      $submit.prop('disabled', true);

      var $hidden = $form.find('input[name="g-recaptcha-response"]');
      if ($hidden.val()) {
        return true;
      }
      $hidden.data('submit-me', true);

      init_recaptcha($form);
      return false;
    };

    $('.g-recaptcha').each(function() {
      var $submit = $(this);
      var $form = $submit.closest('form');
      var $hidden = $form.find('input[name="g-recaptcha-response"]');
      $form.on('submit', submit_form);

      if ($hidden.length == 0) {
        return;
      }
      else if ($submit.is('button[type=submit]')) {
        autoload_recaptcha_forms.push($form);
      }
      else if (typeof(tarteaucitron) != 'undefined') {
        (tarteaucitron.job = tarteaucitron.job || []).push('recaptcha');
        tarteaucitron.user.recaptchaapi = $hidden.data('recaptcha-site-key');

        tarteaucitron.user.recaptchaOnLoad = function() {
          $submit.replaceWith($submit.data('button'));

          grecaptcha_global_loaded = true;        // exécution auto de grecaptcha_global_callback
          grecaptcha_global_load_inited = true;   // ne pas charger recaptcha/api.js deux fois

          // init_recaptcha($form);               // génération du token tout de suite : non
          autoload_recaptcha_forms.push($form);   // reprise de la détection de scroll
        };
      }
    });
  });
})(jQuery);

